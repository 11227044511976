
import { Component, Vue } from "vue-property-decorator";
import AdminTable from "@/components/admin/AdminTable.vue";

@Component({
  components: {
    AdminTable
  }
})
export default class AdminSteamBotProxy extends Vue {
  count = 1;
  code = "";
  percentage = 0.01;
  creationDialog = false;
  columns = [
    {
      name: "_id",
      required: true,
      label: "_id",
      field: "_id",
      align: "left",
      type: "string"
    },
    {
      name: "code",
      align: "center",
      label: "code",
      field: "code",
      type: "string",
      editable: true
    },
    {
      name: "count",
      align: "center",
      label: "count",
      field: "count",
      type: "string",
      editable: true
    },
    {
      name: "percentage",
      align: "center",
      label: "percentage",
      field: "percentage",
      type: "string",
      editable: true
    },
    {
      name: "activatedBy",
      align: "center",
      label: "activatedBy",
      field: "activatedBy",
      type: "string"
    },
    {
      name: "createdAt",
      align: "center",
      label: "createdAt",
      field: "createdAt",
      type: "string"
    }
  ];

  async submit(mutate) {
    const input = {
      percentage: this.percentage,
      count: this.count,
      ...(this.code ? { code: this.code } : {})
    };

    await mutate({
      variables: {
        input
      }
    });
  }
}
